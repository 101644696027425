import { Price, PriceRecurring, PriceWithoutDiscount } from '../types';

export const serviceVersion = '2023-10-31';

export type ServiceParams = {
	getPricingForUser: {
		userId: string;
		promotionCode: string | null;
	};
	createStripeSetupIntent: {
		userId: string;
		stripeVersion: string;
	};
	createSubscription: {
		userId: string;
		stripePaymentMethodId: string;
		stripeQuoteId: string;
	};
};

export type ServiceResponses = {
	getPricingForUser: PricingOffer;
	createStripeSetupIntent: SetupIntent;
	createSubscription: void;
};

type TrialOption = Price & {
	key: string;
	plans: Plans;
};

export type Plans = {
	normal: NormalPlan;
	yearly: YearlyPlan;
	lifetime: LifetimePlan;
};

export type PlanType = keyof Plans;

type NormalPlan = PriceRecurring & {
	priceBehaviorKey: string;
	stripeQuoteId: string;
	monthlyEquivalent: PriceRecurring;
};

type YearlyPlan = PriceRecurring & {
	priceBehaviorKey: string;
	stripeQuoteId: string;
	yearlySaving: PriceWithoutDiscount;
	monthlyEquivalent: PriceRecurring;
};

type LifetimePlan = Price & {
	priceBehaviorKey: string;
	stripeQuoteId: string;
};

type TrialOffer = {
	durationDays: number;
	approxCost: PriceWithoutDiscount;
	options: TrialOption[];
};

export type PricingOffer = {
	currency: string;
	noTrialPlans: Plans | null;
	trial: TrialOffer | null;
};

type SetupIntent = {
	customerId: string;
	customerEphemeralKey: string;
	setupIntentClientSecret: string;
};
